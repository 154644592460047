<template>
    <div id="chat_container">
        <div id="chat_box" ref="box">
            <div v-if="new_message" class="chat_message new_message" @click="scrollToEnd">
                у Вас нове повідомлення
            </div>
            <v-btn
                    class="btn_bottom mx-2"
                    v-if="box_offset - scroll > box_height "
                    fab
                    dark
                    large
                    color="#000"
                    width="50px"
                    height="50px"
                    @click="scrollToEnd"
            >
                <svg width="45px" height="45px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                </svg>
            </v-btn>

            <div
                    v-for="(message, index) in messages"
                    :key="message.id"
                    :id="'message' + (index + 1)"
                    class="chat_message"
                    @click="(guid === message.owner.id) ? editMessage(message, index) : null"
                    :class="guid === message.owner.id ? 'my_message' : 'admin_message'"
                    :style="{marginTop: message.another_date ? '35px' : '0px', minHeight: old_device && ((message.files.length && message.files[0].mime.indexOf('image') !== -1) || (message.files.length && message.files[0].mime.indexOf('video') !== -1)) ? '200px' : !old_device ? 'none' : '30px'}"
            >
                <p v-if="message.another_date" class="date">{{
                    message.createdAt | date_filter(message.another_date)
                    }}</p>

                <!--        Для изменения сообщения-->
                <!--        <textarea v-if="message.isEditing"-->
                <!--                  type="text" v-model="message.message"-->
                <!--                  @blur="message.isEditing = false; editing = false; sendEditedMessage(); $emit('update')"-->
                <!--                  @keydown.enter.prevent="sendEditedMessage()"-->
                <!--                  v-focus-->
                <!--                  cols="30"-->
                <!--        ></textarea>-->
                <!--        <p v-if="!message.isEditing" v-html="message.message"></p>-->


                <p v-html="message.message"></p>

                <img v-if="message.files.length && message.files[0].mime.indexOf('image') !== -1"
                     :src="message.files[0].url"
                     :alt="message.files[0].storageName"
                     @click="openImage(message.files[0])"
                >
                <div class="video_box"
                     v-if="message.files.length && message.files[0].mime.indexOf('video') !== -1"
                     @click="openImage(message.files[0])"
                >
                    <video preload="metadata">
                        <source :src="message.files[0].url + '#t=0.1'" :type="message.files[0].mime">
                    </video>
                    <svg
                            class="video_play"
                            viewBox="0 0 24 24">
                        <path fill="currentColor"
                              d="M10,16.5V7.5L16,12M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"/>
                    </svg>
                </div>
                <div class="time">
                    {{ message.time !== 'NaN:NaN' ? message.time : '' }}
                </div>
            </div>
            <div v-if="chat_closed" class="chat_message chat_closed">
                Чат закритий адміністратором
            </div>
        </div>

        <v-form
                ref="form"
                @submit.prevent="createMessage"
                v-if="!chat_closed"
        >
            <v-file-input
                    v-model="file"
                    hide-input
                    truncate-length="15"
                    class="file_input"
                    ref="file"
                    @change="handleFile"
            ></v-file-input>
            <v-textarea
                    ref="form_area"
                    rows="1"
                    auto-grow
                    class="form_input"
                    color="#000 !important"
                    v-model="text"
                    label="Повідомлення..."
                    filled
                    background-color="#fff"
                    shaped
                    :rules="rules"
                    @input="typing"
                    @blur="resetValidation"
                    @keydown.enter.prevent="createMessage"
                    hide-details
            >
                <template v-slot:append>
                    <v-icon color="#000" @click="createMessage" size="30">
                        {{ icon }}
                    </v-icon>
                </template>
                <template v-slot:prepend-inner>
                    <v-icon color="#000" @click="uploadFile" size="30">
                        {{ file_icon }}
                    </v-icon>
                </template>
            </v-textarea>
            <div v-if="file" class="file_counter">1</div>
        </v-form>
        <v-dialog
                class="image_dialog"
                v-model="dialog"
                fullscreen
                transition="dialog-bottom-transition"
                overlay-color="#000"
                max-width="100%"
        >
            <div class="dialog_box">
                <div class="image_head">
                    <!--          <a v-if="full_image" class="download_image" :href="image_url + full_image.name" :download="full_image.name"-->
                    <!--             target="_blank">-->
                    <a v-if="full_image" class="download_image" :href="full_image.url"
                       :download="full_image.storageName"
                       target="_blank">
                        <svg style="width:30px;height:30px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"/>
                        </svg>
                    </a>
                    <!--            <v-btn v-if="progress === 0" icon width="45px" height="45px" dark @click="downloadImage">-->
                    <!--              <svg style="width:30px;height:30px" viewBox="0 0 24 24">-->
                    <!--                <path fill="currentColor" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"/>-->
                    <!--              </svg>-->
                    <!--            </v-btn>-->
                    <v-progress-circular
                            v-if="progress > 0"
                            :rotate="360"
                            :size="24"
                            :width="6"
                            :value="progress"
                            color="#fff"
                            style="margin-right: 10px;"
                    >
                    </v-progress-circular>
                    <v-btn icon width="45px" height="45px" dark @click="closeImage">
                        <svg style="width:34px;height:34px" viewBox="0 0 24 24">
                            <path fill="currentColor"
                                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                        </svg>
                    </v-btn>
                </div>
                <!--        <img v-if="full_image && full_image.mime.indexOf('image') !== -1" class="full_image"-->
                <!--             :src="image_url + full_image.name" :alt="full_image.name">-->
                <img v-if="full_image && full_image.mime.indexOf('image') !== -1" class="full_image"
                     :src="full_image.url" :alt="full_image.storageName">
                <video class="full_image"
                       controls="controls"
                       v-if="full_image && full_image.mime.indexOf('video') !== -1"
                       preload="metadata"
                >
                    <!--          <source :src="image_url + full_image.name + '#t=0.1'" :type="full_image.mime">-->
                    <source :src="full_image.url + '#t=0.1'" :type="full_image.mime">
                </video>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import socket from 'socket.io-client';

import {
    mdiSendCircleOutline,
    mdiPaperclip
} from '@mdi/js/commonjs/mdi';
import axios from "axios";

export default {
    name: "Chat",
    data: () => ({
        show_chat: false,
        open_answer: false,
        io: null,
        icon: mdiSendCircleOutline,
        file_icon: mdiPaperclip,
        chat: null,
        token: null,
        messages: [],
        text: "",
        rules: [v => !!v || "Text is required"],
        guid: null,
        file: null,
        image_url: 'https://storage.taxiua.app/',
        dialog: false,
        full_image: null,
        progress: 0,
        scroll: 0,
        box_offset: 0,
        box_height: 0,
        questions: null,
        firebase: null,
        old_device: false,
        chat_closed: false,
        new_message: false,
        admin: false,
        editing: false,
        editingMessage: null,

        baseUrl: process.env.VUE_APP_BASE_URL,
        // baseUrl: 'https://chatsapi.taxiua.app',//for testing
        // baseUrl: 'https://api.chats.moyki24.com.ua',
        // socketUrl: 'https://api.chats.moyki24.com.ua',

        regexLink: /(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()]*(\s|$))[^\s]){2,}/gim,// /((http|https):\/\/(www\.)?[a-zа-я0-9-]+\.[a-zа-я0-9-]{2,6})/i,

    }),

    created() {
        this.messages = []
        this.getToken()
        this.setForOldDevice()

        window.addEventListener('message', event => {
            if (event?.data?.type === 'input_message_text') {
                this.text = event.data.data;
            }
        });

    },

    updated() {
        // console.log('update VUE')
        this.$nextTick().then(() => {
            let el = this.$refs.box;
            el.addEventListener('scroll', this.handleScroll)

            // document.addEventListener('keypress', function (ev) {
            // document.addEventListener('keypress', (ev) => {
            //   if (!ev.ctrlKey && !ev.shiftKey && (ev.keyCode === 10 || ev.keyCode === 13)) {
            //     ev.preventDefault()
            //     // if (self.editing) {
            //     if (this.editing) {
            //       // self.sendEditedMessage()
            //       this.sendEditedMessage()
            //       return
            //     }
            //     // if (self.$refs.form_area.isFocused) {
            //     if (this.$refs.form_area.isFocused) {
            //       // self.createMessage()
            //       this.createMessage()
            //     }
            //
            //   }
            // })//TODO: kye press

            if (el.scrollTop === 0 && this.box_offset === 0) {
                this.scrollToEnd()
            }

            if (this.new_message && !(this.box_offset - this.scroll > this.box_height)) {
                this.scrollToEnd()
            }
        })
    },

    filters: {
        date_filter(date, month) {
            if (!date) {
                return null
            }
            let new_date = new Date(date)

            if (month) {
                let new_month = new_date.getMonth() + 1;
                if (new_month < 10) {
                    new_month = '0' + new_month;
                }
                return new_date.getDate() + '.' + new_month + '.' + new_date.getFullYear()
            }

            let minutes = new_date.getMinutes()
            if (minutes < 10) {
                minutes = '0' + new_date.getMinutes()
            }
            return new_date.getHours() + ':' + minutes
        }
    },

    methods: {

        /**
         * Метод отправляет отредактированное сообщение в чат
         * @method
         */
        sendEditedMessage() {
            let message = this.messages[this.editingMessage]
            // console.log(message)
            let msg = this.findLink(this.textMsg)
            let payload = {
                message_id: message?.id,
                message: msg//message?.message
            }

            this.io.emit('message.change', payload)
            this.messages[this.editingMessage].isEditing = false;
            this.editingMessage = null;
            this.editing = false;
        },

        /**
         * Метод получает сообщение и его индекс для редакторования
         * @method
         * @param {String} message - текст сообщения для редактирования
         * @param {Number} index - индекс сообщения
         */
        editMessage(message, index) {
            this.editing = true;
            this.editingMessage = index;
            this.messages[index].isEditing = true;
            // this.sendEditedMessage();
        },

        /**
         * Метод форматирует и записывает дату и время лог чата
         * @method
         * @param {String} new_message - дата сообщения
         */
        setDate(new_message) {
            let date = new Date(this.messages[0]?.createdAt)
            let minutes = date.getMinutes()
            if (minutes < 10) {
                minutes = '0' + minutes
            }
            this.messages[0].time = date.getHours() + ':' + minutes;
            this.messages[0].another_date = true
            if (this.messages.length === 1) {
                return;
            }
            if (new_message) {
                let date = new Date(new_message?.createdAt)
                let prev_dare = new Date(this.messages[this.messages?.length - 2]?.createdAt)
                if (date.getHours() === prev_dare.getHours() && date.getMinutes() === prev_dare.getMinutes()) {
                    this.messages[this.messages.length - 1].time = null
                    return
                }
                let new_minutes = date.getMinutes()
                if (new_minutes < 10) {
                    new_minutes = '0' + new_minutes
                }
                this.messages[this.messages.length - 1].time = date.getHours() + ':' + new_minutes
                return
            }

            for (let i = 1; i < this.messages?.length - 1; i++) {
                let new_date = new Date(this.messages[i]?.createdAt)
                let old_date = new Date(this.messages[i - 1]?.createdAt)
                if (new_date.getHours() === old_date.getHours() && new_date.getMinutes() === old_date.getMinutes()) {
                    this.messages[i].time = null
                } else {
                    let new_minutes = new_date.getMinutes()
                    if (new_minutes < 10) {
                        new_minutes = '0' + new_minutes
                    }
                    this.messages[i].time = new_date.getHours() + ':' + new_minutes;
                }

                if (new_date.getDate() !== old_date.getDate() && new_date.getMonth() !== old_date.getMonth() && new_date.getFullYear() !== old_date.getFullYear()) {
                    this.messages[i].another_date = true
                }
            }
        },

        /**
         * Метод определяет старое устройство
         * @method
         */
        setForOldDevice() {
            let version = navigator.userAgent.split('Chrome/')[1].split(' ')[0].split('.')[0]
            if (+version <= 40) {
                this.old_device = true
            }
        },

        /**
         * Метод получает токен из параметров запроса,
         * а также определяет, является ли пользователь
         * администратором и получает guid
         * @method
         * @returns {Promise<void>}
         */
        getToken: async function () {
            const urlParams = new URLSearchParams(window.location?.search)
            this.firebase = urlParams.get('firebase') || null

            if (urlParams.get('admin')) {
                this.admin = true
            }

            this.token = urlParams.get('token')
// console.log(this.token)

            if (!urlParams.get('chat_id')) {
                await this.createChat()
            } else {
                this.chat = urlParams.get('chat_id')
            }

            await this.getGuid(this.token)
            // this.connectSocket()

        },

        /**
         * Метод получает элемент скрола чата
         * @method
         */
        handleScroll() {
            let el = this.$refs.box;
            this.scroll = el.scrollTop;
        },

        /**
         * Метод получает id чата, открывает чат или создает новый
         * @method
         * @returns {Promise<void>}
         */
        async createChat() {
            let info = {
                name: 'Не може ввійти',
                type: 'support',
                loadLast: true,
            };

            await axios.post(`${this.baseUrl}/api/users/chats/create?token=${this.token}`, info, {
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${this.token}`
                }
            })
                .then(responce => {
                    console.log(responce)
                    this.chat = responce.data.id
                })
                .catch(error => {
                    console.log(error);
                })
        },

        /**
         * Метод получает guid из токена
         * @method
         * @param {String} token - токен чата
         */
        getGuid(token) {
            this.guid = JSON.parse(window.atob(token.split('.')[1]))?.id;
            this.connectSocket()
        },

        /**
         * Открывает изображение в полноэкранном режиме
         * @method
         * @param image
         */
        openImage(image) {
            this.full_image = image;
            this.dialog = true
        },

        /**
         * Метод закрывает полноэкранный режим показа изображения
         * @method
         */
        closeImage() {
            this.full_image = null
            this.dialog = false
            this.progress = 0
        },

        /**
         * Метод открывает окно загрузки изображения
         * @method
         */
        uploadFile() {
            this.$refs.file.$refs.input.click()
        },

        /**
         * Метод получает и записывает загружаемый файл
         * @method
         */
        handleFile() {
            this.file = this.$refs.file.$refs.input.files[0]
        },

        /**
         * Метод проскороливает чат в конец
         * @method
         */
        scrollToEnd() {
            let el = this.$refs.box;
            this.box_height = el.clientHeight;
            let scroll_el = document.getElementById('message' + this.messages.length);

            if (scroll_el) {
                let scroll = scroll_el?.offsetTop
                el.scrollTop = scroll
                this.box_offset = scroll
                setTimeout(function () {
                    el.scrollTop = scroll
                }, 200)
            }
            this.new_message = false
        },

        /**
         * Метод подключает чат к io.socket
         * @method
         */
        connectSocket() {
            this.messages = [];
            this.io = socket(this.baseUrl, {
                query: {
                    token: this.token,
                    chat_id: this.chat
                },
                transports: ["websocket"]
            });


            this.io.on('user.connect', async () => {
                // console.log("new user connect");
            });

            this.io.on('sync.messages', async (data) => {
                // console.log('chat sync');
                // console.log(data);
                if (this.messages.length) this.messages = []
                for (let i = data.length - 1; i >= 0; i--) {
                    let dat = data[i];
                    dat.isEditing = false;
                    this.messages.unshift(dat)
                }
                this.setDate();
            });

            this.io.on('sync.chat.info', async (data) => {
                // console.log('sync chat info');
                this.messages.push(data);
                // console.log(data.members);
                this.setDate(data)
            });

            this.io.on('message.new', async (data) => {
                // console.log('new message');
                // console.log(data);

                let dat = data;
                dat.isEditing = false;
                this.messages.push(dat);
                this.new_message = true;
                this.setDate(data);
            });

            this.io.on('message.update', async (data) => {
                // console.log('update message');
                // console.log(data);

                for (let message of this.messages) {
                    if (message?.id === data?.id) {
                        message.message = data.message;
                    }
                }
            });

            this.io.on("kick", () => {
                // console.log('kick');
                this.chat_closed = true
            })

            this.io.on('user.disconnect', async () => {
                // console.log("user disconnect");
                // this.messages.push({
                //   message: 'User left the chat',
                //   type: 'text'
                // })
            });

        },

        /**
         * Метод отправляет новое сообщение в чат
         * @method
         */
        async createMessage() {
            let msg = this.findLink(this.text)
            if (this.file) {
                let data = new FormData();
                data.append("message", msg/*this.text*/);
                data.append("chat", this.chat);
                data.append("files", this.file, this.file.name);

                this.file = null
                this.text = ''
                this.resetValidation();

                // await axios.post(`https://api.chats.moyki24.com.ua/api/users/messages/send?token=${this.token}`, data,  {
                await axios.post(`${this.baseUrl}/api/users/messages/send?token=${this.token}`, data, {
                    headers: {
                        'accept': '*/*',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.token}`
                    }
                })
                    .then(() => {
                        this.scrollToEnd()
                    })
                    .catch(error => {
                        console.log(error);
                    })
                return
            }
            if (this.$refs.form.validate()) {
                this.io.emit("message", {
                    message: msg,//this.text,
                    type: 'text'
                })
                this.scrollToEnd()
                this.text = '';
                this.resetValidation();
            }
        },

        /**
         * Метод валидирует форму отправки сообщения
         * @method
         */
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        typing() {

        },

        /**
         * Метод отключает чат от io.socket
         * @method
         */
        disconnectChat() {
            if (this.io) {
                this.io.on('disconnect', () => {
                    console.log('disconnected')
                });

                this.io.off('user.connect', async () => {
                });

                this.io.off('message.new', async () => {
                });

                this.io.off('message.update', async () => {
                });

                this.io.off('sync.chat.info', async () => {
                });

                this.io.off('sync.messages', async () => {
                });

                this.io.off('kick', () => {
                });

                this.io.off('')
            }
        },


        findLink(str) {
            console.log(str)
            const links = str.match(this.regexLink)
            console.log(links)
            if (!links?.length) return str
            let res = str.replace(this.regexLink, st => `<a href='${st}' target='_blank' style='color: inherit'>${st}</a>`)
            console.log(res)
            return res
        },
    },

    directives: {
        focus: {
            inserted(el) {
                el.focus()
            }
        }
    },

    beforeDestroy() {
        if (this.show_chat) {
            this.io.disconnect();
            this.disconnectChat();
        }
    }

};
</script>

<style scoped>
#chat_container {
    background-color: #FAF9FE;
    font-family: 'Red Hat Display', sans-serif;
    position: relative;
    overflow: hidden;
}

#chat_box {
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
}

#chat_box::-webkit-scrollbar {
    width: 5px;
    color: #212121;
}

#chat_box::-webkit-scrollbar-thumb {
    background-color: #212121;
}

form {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.form_input {
    border-radius: 0;
    z-index: 202;
}

.chat_message:last-child {
    margin-bottom: 75px !important;
}

.chat_message {
    max-width: 80%;
    padding: 5px 10px;
    margin: 3px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    position: relative;
}

.chat_message textarea {
    padding: 0 5px;
    line-height: 1.15;
}

.chat_message textarea:focus {
    outline: none;
}

.chat_message img, .chat_message video {
    display: block;
    max-width: 300px;
    border-radius: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.chat_message p {
    margin: 0;
    word-break: break-word;
    white-space: pre-wrap;
    line-height: 1.25;
}

.time {
    position: absolute;
    font-size: 10px;
}

.date {
    position: absolute;
    word-break: normal !important;
    top: -30px;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 10px;
    padding: 3px 10px;
    padding-bottom: 0;
    color: white;
}

.admin_message .date {
    left: calc(50vw - 50px);
}

.my_message .date {
    right: calc(50vw - 50px);
}

.my_message {
    background-color: rgba(172, 243, 175, .4);
    text-align: right;
    align-self: flex-end;
}

.my_message .time {
    left: -40px;
    top: 3px;
    align-self: flex-end;
}

.admin_message {
    text-align: left;
    background-color: #212121;
    color: #fff;
}

.admin_message .time {
    align-self: flex-start;
    right: -40px;
    top: 3px;
    color: #212121;
}

.file_input {
    visibility: hidden;
}

.dialog_box {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 44px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image_dialog {
    z-index: 9999 !important;
}

.image_head {
    height: 45px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: #212121;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
}

.full_image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}

.file_counter {
    position: absolute;
    right: 10px;
    bottom: 12px;
    width: 14px;
    height: 14px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
    background-color: #ff0000;
    line-height: 1.3;
    z-index: 999;
    text-align: center;
}

.video_box {
    position: relative;
}

.video_play {
    position: absolute;
    left: calc(50% - 20px);
    bottom: calc(50% - 20px);
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.video_play path {
    fill: #fff;
}

.btn_bottom {
    position: absolute;
    right: 0;
    bottom: 65px;
    z-index: 99;
}

.new_message {
    z-index: 2;
    background-color: rgba(0, 0, 0, .4);
    color: #fff;
    position: absolute;
    bottom: 65px;
    left: 5%;
}

::v-deep .v-textarea textarea {
    line-height: 1.25em;
}

#item_group {
    height: 100%;
    margin: 0 auto;
    display: block;
}

.item {
    height: auto !important;
    margin: 0 auto;
    width: 94%;
    max-width: 640px;
    position: relative;
    margin-bottom: 6px;
}

.item:hover {
    background-color: transparent !important;
}

::v-deep .v-expansion-panel-header {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.03);
    border-radius: 8px !important;
    padding: 25px 14px;
    background-color: #ffffff;
}

::v-deep .v-expansion-panel-content {
    width: 94%;
    margin: 0 auto;
    padding-top: 10px;
    overflow: hidden;
    font-size: 12px;
}

.v-expansion-panel::before {
    box-shadow: none;
}

::v-deep .v-expansion-panel-content ol {
    padding-left: 10px;
}

::v-deep .v-expansion-panel-content li {
    max-width: 300px;
    margin-bottom: 20px;
}

::v-deep .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child):after {
    border: none;
}

h1 {
    font-size: 18px;
    margin: 10px;
    text-align: center;
}

h2 {
    font-size: 16px;
    width: 95%;
}

.item_header {
    font-weight: bold;
    font-size: 16px;
}

.item_content {
    font-size: 16px;
    padding-bottom: 10px;
}

.item_content p {
    margin-bottom: 0;
}

::v-deep .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
}

.item_content > div > div {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}

.item_button {
    width: 100px !important;
}

.answer_modal {
    padding: 15px;
    text-align: center;
    font-size: 16px;
}

.download_image {
    color: #fff;
    width: 34px;
    height: 34px;
    margin-top: 2px;
}

.chat_closed {
    background-color: #d84315;
    color: #fff;
    font-weight: bold;
    align-self: center;
    margin-top: 20px;
}

</style>
